import styled from "styled-components";
import cx from "classnames";

const StyledCharCount = styled.div`
  color: #646464;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-align: right;
`;

const CharCount = ({ max, count }) => {
  const maxReached = count >= max;

  return (
    <StyledCharCount className={cx("char-count", { error: maxReached })}>
      {count}/{max}
    </StyledCharCount>
  );
};

export default CharCount;
