import styled, { css } from "styled-components";
import tw from "twin.macro";

const badgeStyles = css`
  font-size: 10px;
  line-height: 12px;
  padding: 4px 6px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  letter-spacing: 0.05em;
`;

const errorStyles = tw`bg-error bg-opacity-10 text-error`;

const disclaimerStyles = css`
  font-size: 10px;
  line-height: 13px;
  padding: 4px 8px;
  font-weight: bold;
  border-radius: 4px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.textGray.lighter};
  background: ${({ theme }) => theme.colors["pulp-white"]};
`;

/**
 * @deprecated - use custom css per use case
 */
const Highlight = styled.div`
  padding: 0.75rem 1rem;
  background: #eef0fa;
  border-radius: 6px;
  font-size: 13px;
  line-height: 16px;

  ${(props) => props.error && errorStyles}

  ${(props) => props.badge && badgeStyles}

  ${(props) => props.disclaimer && disclaimerStyles}
`;

export default Highlight;
