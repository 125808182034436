import styled from "styled-components";

/**
 * @deprecated - use custom css per use case
 */
const Divider = styled.hr`
  background: #242226;
  opacity: 0.1;
`;

export default Divider;
