import styled from "styled-components";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import useViewport from "../../utils/hooks/useViewport";
import { AnimatePresence, domAnimation, LazyMotion, m } from "framer-motion";
import useHandleOutsideClick from "../../utils/hooks/useHandleOutsideClick";

const StyledTooltipContainer = styled(m.div)`
  filter: drop-shadow(0 12px 12px rgba(35, 35, 35, 0.15));
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  pointer-events: none;
  z-index: 100;
  max-width: 100vw;
  will-change: opacity;
  padding: 0 0.5rem;
  min-width: ${(props) => props.$minWidth}px;

  .tooltip {
    background: ${(props) => props.$backgroundColor};
    border-radius: 8px;
    position: relative;
    padding: 10px;
    font-size: 12px;
    font-weight: bold;
    pointer-events: none;
  }
`;

const StyledIndicator = styled.svg`
  margin: 0 auto;
`;

const Indicator = ({ backgroundColor }) => (
  <StyledIndicator
    width="33"
    height="8"
    viewBox="0 0 33 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 8C16.5 8 16.5 0 0.5 0H32.5C17.1957 0 16.5 8 16.5 8Z"
      fill={backgroundColor}
    />
  </StyledIndicator>
);

const Tooltip = forwardRef(
  (
    { children, minWidth, content, backgroundColor, renderTrigger, container },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const localRef = useRef();
    const tooltipRef = ref || localRef;
    const innerRef = useRef();
    const { width } = useViewport();

    useEffect(() => {
      if (visible) {
        const tooltipRect = innerRef.current?.getBoundingClientRect() || {};
        if (!tooltipRect) {
          return;
        }

        const propContainerRect = container?.getBoundingClientRect() || {};

        let containerRect = {
          width: propContainerRect.width || window.innerWidth,
          left: propContainerRect.left || 0,
        };

        const overflowLeft = tooltipRect.left - containerRect.left;
        const overflowRight =
          containerRect.left +
          containerRect.width -
          (tooltipRect.left + tooltipRect.width);

        if (overflowLeft < 0) {
          innerRef.current.style.cssText = `right: ${overflowLeft}px`;
        } else if (overflowRight < 0) {
          innerRef.current.style.cssText = `left: ${overflowRight}px`;
        }
      }
    }, [container, visible]);

    useEffect(() => {
      setVisible(false);
    }, [width]);

    const buttonRef = useRef();
    const closeTooltip = () => setVisible(false);
    const toggleTooltip = () => setVisible((visible) => !visible);

    useHandleOutsideClick(buttonRef, closeTooltip, visible);

    return (
      <LazyMotion features={domAnimation}>
        <button
          type={"button"}
          ref={buttonRef}
          className={"inline-grid relative"}
          onClick={toggleTooltip}
        >
          <AnimatePresence>
            {visible && (
              <StyledTooltipContainer
                ref={tooltipRef}
                className={"tooltip-container"}
                $minWidth={minWidth}
                $backgroundColor={backgroundColor}
                $visible={visible}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <div className={"tooltip"} ref={innerRef}>
                  {content}
                </div>
                <Indicator backgroundColor={backgroundColor} />
              </StyledTooltipContainer>
            )}
          </AnimatePresence>
          {renderTrigger ? renderTrigger(visible) : children}
        </button>
      </LazyMotion>
    );
  }
);

Tooltip.defaultProps = {
  minWidth: 200,
  backgroundColor: "#fff",
};

export default Tooltip;
