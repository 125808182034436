import { useRef } from "react";
import cx from "classnames";
import { useSelect } from "downshift";
import {
  StyledDropdownIndicator,
  StyledSelectContainer,
  StyledSelectToggle,
  StyledSelectMenu,
  StyledMenuItem,
} from "./styles";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

const DropdownIndicator = ({ isOpen }) => {
  return (
    <StyledDropdownIndicator className="dropdown-indicator" $isOpen={isOpen}>
      <svg
        width="11"
        height="5"
        viewBox="0 0 11 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ vectorEffect: "non-scaling-stroke" }}
      >
        <path
          d="M1 1L5.22265 3.8151C5.3906 3.92707 5.6094 3.92707 5.77735 3.8151L10 1"
          stroke="#2D1D46"
          strokeLinecap="round"
        />
      </svg>
      <svg
        className="menu-indicator"
        xmlns="http://www.w3.org/2000/svg"
        width="24.5"
        height="6.71"
        viewBox="0 0 24.5 6.71"
      >
        <path
          d="M24.5,6.21H23.36a4,4,0,0,1-2.27-.71L14.87,1.21a4,4,0,0,0-4.55,0L4.1,5.5a4,4,0,0,1-2.27.71H0v.5H24.5Z"
          style={{ fill: "#fff", fillRule: "evenodd" }}
        />
        <path
          d="M24.5,6.21H23.36a4,4,0,0,1-2.27-.71L14.87,1.21a4,4,0,0,0-4.55,0L4.1,5.5a4,4,0,0,1-2.27.71H0"
          style={{
            fill: "none",
            stroke: "#d7cad6",
            strokeMiterlimit: 10,
            fillRule: "evenodd",
          }}
        />
      </svg>
    </StyledDropdownIndicator>
  );
};

const Select = (props) => {
  const {
    id,
    label,
    items,
    btnWidth,
    menuWidth,
    maxMenuHeight,
    itemToString,
    analyticsLabel,
    initialSelectedItem,
    placeholder,
    onChange,
    onBlur,
    selectedItem,
    disabled,
    error,
    helpText,
    name,
    className,
  } = props;

  const toggleBtnRef = useRef(null);

  const handleChange = ({ selectedItem }) => {
    onChange(selectedItem);
  };

  const onStateChange = (state) => {
    if (state.type === "__menu_blur__") {
      const e = new Event("blur");
      Object.defineProperty(e, "target", {
        value: toggleBtnRef.current,
      });
      onBlur && onBlur(e);
    }
  };

  const {
    isOpen,
    getToggleButtonProps,
    getItemProps,
    highlightedIndex,
    getLabelProps,
    selectedItem: dsSelectedItem,
    getMenuProps,
  } = useSelect({
    items,
    itemToString,
    selectedItem,
    initialSelectedItem,
    onSelectedItemChange: handleChange,
    onStateChange,
    id,
  });

  // Use ref to get btn width, set default min menu width to button width
  const btnAutoWidth =
    toggleBtnRef &&
    toggleBtnRef.current &&
    `${toggleBtnRef.current.offsetWidth}px`;

  const selected = dsSelectedItem || initialSelectedItem;

  return (
    <StyledSelectContainer
      className={cx("select-container", { error }, className)}
    >
      {label && <label {...getLabelProps()}>{label}</label>}
      <StyledSelectToggle
        name={name}
        type={"button"}
        data-analytics={analyticsLabel}
        $width={btnWidth || "auto"}
        className={cx("select-toggle", {
          populated: selected,
          open: isOpen,
          error,
        })}
        {...getToggleButtonProps({
          ref: toggleBtnRef,
          disabled,
        })}
      >
        <span>{selected ? itemToString(selected) : placeholder}</span>
        {!disabled && <DropdownIndicator isOpen={isOpen} />}
      </StyledSelectToggle>
      <StyledSelectMenu
        className={cx("select-menu", { "is-open": isOpen })}
        $maxHeight={maxMenuHeight || "285px"}
        $width={menuWidth || "auto"}
        $minWidth={menuWidth || btnWidth || btnAutoWidth}
        {...getMenuProps()}
      >
        {items.map((item, index) => {
          const key = (name || id) + "-option-" + index;
          return (
            <StyledMenuItem
              key={key}
              className={cx("menu-item", {
                highlighted: highlightedIndex === index,
                selected: selected && isEqual(selected, item),
              })}
              {...getItemProps({
                key,
                index,
                item,
              })}
            >
              {itemToString(item)}
            </StyledMenuItem>
          );
        })}
      </StyledSelectMenu>
      {error && <div className={"error-text"}>{error}</div>}
      {!error && helpText && <div className={"help-text"}>{helpText}</div>}
    </StyledSelectContainer>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  btnWidth: PropTypes.string,
  menuWidth: PropTypes.string,
  maxMenuHeight: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array.isRequired,
  itemToString: PropTypes.func,
  analyticsLabel: PropTypes.string,
  initialSelectedItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  selectedItem: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onBlur: PropTypes.func,
  name: PropTypes.string,
};

Select.defaultProps = {
  placeholder: "Select...",
  itemToString: (item) => item.label,
};

export default Select;
