import styled from "styled-components";

export const StyledTextField = styled.div(
  ({ theme }) => `
    padding-bottom: 1rem;
    position: relative;
    color: ${theme.colors.textGray.default};
    
    .wrapper {
      position: relative;
      margin-top: 7px;
    }
    
    label {
      display: block;
      font-size: 14px;
      line-height: 1;
    }
    input,
    textarea,
    &.stripe-container {
      background-color: #ffffff;
      --border-color: rgba(36, 34, 38, 0.3);
      border: 1px solid var(--border-color);
      border-radius: 6px;
      min-height: 40px;
      padding: 0.5rem 0.875rem;
      font-size: 14px;
      resize: none;
      color: #4d4b4e;
      -webkit-appearance: none;
      width: 100%;

      &::placeholder {
        color: #4d4b4e;
        opacity: 0.38;
      }

      &:hover,
      &[disabled] {
        background-color: #f7f7f7;
      }
      
      &[disabled] {
        color: rgba(77, 75, 78, 0.7);
      }


      &:focus {
        box-shadow: 0 0 0 1px var(--border-color);
        background: #fff;
      }
    }
    .error-text,
    .help-text {
      margin-top: 0.25rem;
      font-size: 12px;
    }

    .error-text {
     color: ${theme.colors.error};
    }

    .help-text {
      color: ${theme.colors.textGray.lighter};
    }

    &.error {
      label {
        color: ${theme.colors.error};
      }
      input,
      textarea {
        --border-color: ${theme.colors.error};
      }
    }

    input {
      text-overflow: ellipsis;
      &.has-submit {
        padding-right: 2rem;
      }
    }

    button[type="submit"] {
      position: absolute;
      top: 50%;
      right: 0.875rem;
      transform: translateY(-50%);
      cursor: pointer;
    }
    
    textarea:focus + .char-count.error {
      color: ${theme.colors["error"]};
    }
`
);
