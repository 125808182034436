import PropTypes from "prop-types";
import cx from "classnames";
import Icon from "@/atoms/Icon";
import { forwardRef, memo } from "react";
import { StyledTextField } from "./styles";
import Tooltip from "@/atoms/Tooltip";
import CharCount from "@/atoms/TextField/CharCount";
import { LINKS } from "globalConfig";
import { useFormContext } from "@/atoms/account/formik/FormProvider";

const SubmitButton = memo(({ loading, onSubmit, disabled }) => (
  <button type={"submit"} onClick={onSubmit} disabled={disabled}>
    <Icon name={loading ? "loading" : "arrow-right"} size={"1rem"} />
  </button>
));

const TooltipIcon = ({ tooltipContent, tooltipMinWidth }) => (
  <Tooltip
    content={<div className={"text-white"}>{tooltipContent}</div>}
    backgroundColor={"#596ECA"}
    minWidth={tooltipMinWidth}
    renderTrigger={(visible) => (
      <Icon
        name={"help-outline"}
        size={"0.75rem"}
        fill={visible ? "#596ECA" : "#797979"}
      />
    )}
  />
);

const TextField = forwardRef(
  (
    {
      label,
      helpText,
      analyticsLabel,
      className,
      error,
      onSubmit,
      onKeyPress,
      disabled,
      loading,
      submitBtn,
      tooltip,
      tooltipMinWidth,
      id,
      maxLength,
      isLoginPasswordField,
      ...props
    },
    ref
  ) => {
    const { setFieldError } = useFormContext();

    const handleOnKeyPress = (event) => {
      if (onKeyPress) {
        onKeyPress(event);
      } else if (onSubmit && event.key === "Enter") {
        onSubmit(props.value);
      }
    };

    const inputProps = {
      ...props,
      id: id || props.name,
      ref,
      onKeyPress: handleOnKeyPress,
      disabled: disabled || loading,
      maxLength,
    };

    const handleChange = (event) => {
      props?.onChange?.(event);

      if (isLoginPasswordField) {
        // clear any API errors that were persisted for the login password field
        setFieldError(props.name, "");
      }
    };

    // Only password inputs on Login (i.e. not Signup) should show "Forgot your password?"
    const shouldShowForgotPasswordLink =
      props?.name === "password" && isLoginPasswordField;

    return (
      <StyledTextField
        className={cx("field", className, { error })}
        data-analytics={analyticsLabel}
      >
        {label && (
          <div className={"flex gap-2 items-center"}>
            <label htmlFor={inputProps.id}>{label}</label>
            {tooltip && (
              <TooltipIcon
                tooltipContent={tooltip}
                tooltipMinWidth={tooltipMinWidth}
              />
            )}
          </div>
        )}
        <div className={"wrapper"}>
          {props.type === "textarea" ? (
            <>
              <textarea {...inputProps} rows={props.rows} />
              {maxLength && (
                <CharCount
                  max={maxLength}
                  count={inputProps.value?.length || 0}
                />
              )}
            </>
          ) : (
            <>
              <input
                {...inputProps}
                onChange={handleChange}
                className={cx("w-full", props.className, {
                  "has-submit": onSubmit,
                })}
              />
              {onSubmit &&
                (submitBtn || (
                  <SubmitButton
                    onSubmit={() => onSubmit(props.value)}
                    disabled={props.disabled}
                    submitBtn={submitBtn}
                    loading={props.loading}
                  />
                ))}
            </>
          )}
        </div>
        {error && (
          <div className={"error-text"}>
            {error}{" "}
            {shouldShowForgotPasswordLink && (
              <a href={LINKS.resetPassword}>Forgot your password?</a>
            )}
          </div>
        )}
        {!error && helpText && <div className={"help-text"}>{helpText}</div>}
      </StyledTextField>
    );
  }
);

TextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  analyticsLabel: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  validate: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  onKeyPress: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.number,
  submitBtn: PropTypes.any,
  tooltip: PropTypes.any,
  tooltipMinWidth: PropTypes.number,
  isLoginPasswordField: PropTypes.bool,
};

TextField.defaultProps = {
  type: "text",
  isLoginPasswordField: false,
};

export default TextField;
