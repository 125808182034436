import React from "react";
import {
  Formik,
  Form,
  FormikHelpers,
  FormikValues,
  FormikErrors,
  FormikTouched,
} from "formik";
import { isEqual } from "lodash";
import {
  withFormContext,
  useFormContext,
  FormikFormProps,
  FormContextValue,
} from "@/atoms/account/formik/FormProvider";
import { isAPIError } from "lib/api/base";
import { useToasts } from "react-toast-notifications";
import { formatToast } from "utils";
import { GENERIC_ERROR_MESSAGE } from "constants/forms";

interface SubmitFormikProps<Values extends FormikValues> {
  onSubmit: () => Promise<any> | Values;
  onSuccess?: (data: Values) => void;
  formikProps: FormikHelpers<Values>;
  resetFieldErrors: FormContextValue["resetFieldErrors"];
  onGenericError?: (message: string) => void;
}

async function submitFormik<Values extends FormikValues>({
  onSubmit,
  onSuccess,
  formikProps,
  resetFieldErrors,
  onGenericError,
}: SubmitFormikProps<Values>) {
  const { setFieldError, setStatus } = formikProps;
  const res = await onSubmit();
  const handleSuccess = async () => {
    resetFieldErrors();
    onSuccess && (await onSuccess(res));
  };
  const handleGenericError = onGenericError ? onGenericError : setStatus;

  if (isAPIError(res)) {
    const { error } = res;
    if (error?.fieldErrors) {
      Object.entries(error.fieldErrors).map(([field, error]) => {
        setFieldError(field, error);
      });
    } else {
      handleGenericError(error?.message || GENERIC_ERROR_MESSAGE);
    }
  } else {
    return handleSuccess();
  }
}

interface AccessibleErrorsProps<Values extends FormikValues> {
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
}

function AccessibleErrors<Values extends FormikValues>({
  errors,
  touched,
}: AccessibleErrorsProps<Values>) {
  const errorFields = Object.keys(errors);
  const displayableErrorFields = errorFields.filter((f) => touched[f]);
  const numErrors = displayableErrorFields.length;
  return numErrors ? (
    <div role="alert" className={"sr-only"}>
      <h3>
        There {numErrors === 1 ? "is 1 error" : `are ${numErrors} errors`} in
        this form:
      </h3>
      <ul>
        {displayableErrorFields.map((f) => (
          <li key={f}>
            {f} : {JSON.stringify(errors[f])}
          </li>
        ))}
      </ul>
    </div>
  ) : null;
}

function FormikForm<Values extends FormikValues>({
  initialValues,
  validationSchema,
  onSubmit,
  onSuccess,
  children,
  className,
  submitPristine,
  toastOnGenericError,
  id,
  ...props
}: FormikFormProps<Values>) {
  const { resetFieldErrors } = useFormContext();
  const { addToast } = useToasts();
  let onGenericError: (message: string) => void;

  if (toastOnGenericError) {
    onGenericError = (message: string) => {
      addToast(
        ...formatToast(
          message || "There was an error on our server. Please try again.",
          {
            appearance: "error",
          }
        )
      );
    };
  }

  const handleSubmit = async (
    values: Values,
    formikProps: FormikHelpers<Values>
  ) => {
    const shouldSubmit = !isEqual(initialValues, values) || submitPristine;
    return await submitFormik({
      onSubmit: () => (shouldSubmit ? onSubmit(values) : values),
      onSuccess,
      onGenericError,
      formikProps,
      resetFieldErrors,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      {...props}
    >
      {({ errors, touched }) => {
        return (
          <Form className={className} id={id}>
            <AccessibleErrors errors={errors} touched={touched} />
            {children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default withFormContext(FormikForm);
