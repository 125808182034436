import { StyledCheckbox } from "./styles";
import Icon from "@/atoms/Icon";

const Checkbox = ({ children, ...props }) => {
  return (
    <StyledCheckbox>
      <input type={"checkbox"} {...props} checked={props.checked} />
      <div className="icon-container">
        <Icon name={"checkmark"} size={"12px"} />
      </div>
      {children}
    </StyledCheckbox>
  );
};

export default Checkbox;
