import Button from "@/atoms/Button";
import { useFormikContext } from "formik";

/**
 * @deprecated - use `public-ui-shared-react` `Button` component
 * @param {object} props
 * @param {string} [props.className]
 * @param {string} [props.label]
 * @param {boolean} [props.allowPristine]
 * @param {string | null} [props.level]
 * @param {string} [props.color]
 * @param {string} [props.form]
 */
const FormikSubmit = ({
  label,
  className,
  allowPristine,
  level,
  color,
  form,
}) => {
  const { isValid, isSubmitting, dirty } = useFormikContext();
  return (
    <Button
      label={label}
      className={className}
      loading={isSubmitting}
      disabled={!allowPristine && (!isValid || !dirty)}
      type={"submit"}
      color={color || "teal"}
      level={level}
      form={form}
    />
  );
};

export default FormikSubmit;
