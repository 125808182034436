import { forwardRef, ReactNode } from "react";
import { Field, useField } from "formik";
import Select from "@/atoms/Select";

interface Props extends React.HTMLProps<HTMLSelectElement> {
  name: string;
  children?: ReactNode;
  helpText?: string;
  tooltip?: string;
  placeholder?: string;
  tooltipMinWidth?: number;
  isLoginPasswordField?: boolean;
  itemToString?: (item: any) => string;
  itemToValue?: (item: any) => string;
  items: Array<object | string>;
}

/**
 * @deprecated - use `components/DLS/DLSSelect.tsx`
 */
export const FormikSelect = forwardRef<HTMLSelectElement, Props>(
  (
    {
      name,
      helpText,
      placeholder,
      tooltip,
      tooltipMinWidth,
      id,
      label,
      itemToString = (object) => object.label,
      itemToValue = (object) => object.value,
      items,
      as,
      ...props
    },
    ref
  ) => {
    const itemsAreObjects = typeof items[0] === "object";
    const [field, meta, helpers] = useField(name);

    const handleSelect = (item: object | string) =>
      helpers.setValue(itemsAreObjects ? itemToValue(item) : item);

    const selectedItem = itemsAreObjects
      ? items.find(
          (item: object | string) => itemToValue(item) === field.value
        ) || null
      : field.value;

    const attrs = {
      name,
      label,
      helpText,
      tooltip,
      tooltipMinWidth,
      id: id || name,
      placeholder: placeholder || `Enter your ${label?.toLowerCase()}`,
      onChange: handleSelect,
      selectedItem,
    };

    const toString = itemsAreObjects ? itemToString : (item: object) => item;

    return (
      <Field
        ref={ref}
        as={as || Select}
        btnWidth={"100%"}
        items={items}
        {...props}
        {...field}
        {...attrs}
        itemToString={toString}
        error={meta.touched && meta.error}
      />
    );
  }
);

export default FormikSelect;
