import styled from "styled-components";
import { media } from "@literati/public-ui-shared-react";
import tw from "twin.macro";

export const StyledDropdownIndicator = styled.div`
  ${tw`ml-2 relative h-full flex items-center`}
  .menu-indicator {
    position: absolute;
    left: 50%;
    bottom: calc(-1rem - 1px);
    transform: translate3d(-50%, 0, 0);
    z-index: 20;
    display: ${(props) => (props["$isOpen"] ? "block" : "none")};
  }
`;

export const StyledSelectContainer = styled.div`
  ${tw`relative`}

  &.error {
    label {
      ${tw`text-error`}
    }
  }

  * {
    text-align: left;
  }

  label {
    display: block;
    line-height: 1;
    margin-bottom: 4px;
    font-size: 14px;
  }

  .error-text,
  .help-text {
    ${tw`mt-1`}
    font-size: 12px;
  }

  .error-text {
    ${tw`text-error`}
  }

  .help-text {
    color: #828282;
  }
`;

export const StyledSelectToggle = styled.button`
  ${tw`flex items-center justify-between`}
  background-color: #ffffff;
  --border-color: rgba(36, 34, 38, 0.3);
  border: 1px solid var(--border-color);
  height: 40px;
  border-radius: 6px;
  padding: 0 1rem;
  font-size: 14px;
  margin-top: 7px;
  line-height: 1.2;
  width: ${(props) => props["$width"]};

  &.error {
    --border-color: ${({ theme }) => theme.colors.error};
  }

  &:not(.populated) {
    span {
      opacity: 0.38;
    }
  }

  &.open {
    box-shadow: 0 0 0 1px var(--border-color) !important;
  }

  &:hover,
  &[disabled] {
    background-color: #fafafa;
  }
`;

export const StyledSelectMenu = styled.ul`
  ${media["max:sm"]} {
    max-height: 140%;
  }
  ${tw`absolute my-4 py-2 bg-white text-ink-black hidden`}
  overflow: scroll;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #d7cad6;
  max-height: ${(props) => props["$maxHeight"]};
  width: ${(props) => props["$width"]};
  min-width: ${(props) => props["$minWidth"]};

  &.is-open {
    ${tw`z-10 block opacity-100`}
  }
`;

export const StyledMenuItem = styled.li`
  ${tw`relative block whitespace-nowrap px-4 py-2`}
  ${tw`text-inherit no-underline`}
    ${tw`transition duration-100`}
    cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.2;
  &.selected,
  &.highlighted,
  &:hover,
  &:active,
  &:focus {
    background: #f2f1f5;
  }
`;
