import Checkbox from "@/atoms/Checkbox";
import { Field, useField } from "formik";
import React from "react";

/**
 * @deprecated - use `/components/DLS/DLSCheckbox.js`
 */
const FormikCheckbox = ({ children, name, ...props }) => {
  const [field, meta] = useField(name);
  const error = (meta.touched && meta.error) || undefined;

  return (
    <Field
      name={name}
      as={Checkbox}
      error={error}
      checked={field.value}
      {...props}
    >
      {children}
    </Field>
  );
};

export default FormikCheckbox;
