import { ParsedErrorResponse } from "lib/api/base";
import { FormikValues } from "formik";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

/** An object returned from backend that contains errors for any fields that were submitted */
export type FormContextFieldErrors = Record<string, string>;

export interface FormContextValue {
  fieldErrors: FormContextFieldErrors;
  /** Updates entire `fieldErrors` object */
  setFieldErrors: React.Dispatch<FormContextFieldErrors>;
  /** Updates a single field out of the `fieldError` object */
  setFieldError: (name: string, error: string) => void;
  /** Reset all fields in the `fieldError` object back to their defaults */
  resetFieldErrors: () => void;
}

export const FORM_CONTEXT_DEFAULT_FIELD_ERRORS = {};

/**
 * A known bug in Formik causes imperatively set input errors to get erased on blur events.
 * (i.e. errors set with Formik's `setFieldErrors` get erased on blur if form-level validation is occurring)
 * Save these errors in a `fieldErrors` context value for displaying in any consuming input field children.
 * @see https://github.com/jaredpalmer/formik/issues/834
 */
export const FormContext = createContext<FormContextValue>({
  fieldErrors: FORM_CONTEXT_DEFAULT_FIELD_ERRORS,
  setFieldErrors: () => {
    // do nothing
  },
  resetFieldErrors: () => {
    // do nothing
  },
  setFieldError: () => {
    // do nothing
  },
});

const FormProvider = FormContext.Provider;

export default FormProvider;

export interface FormikFormProps<Values extends FormikValues> {
  className?: string;
  initialValues: Values;
  validationSchema?: object;
  onSubmit: (data: Values) => Promise<object | ParsedErrorResponse>;
  onSuccess?: (data: any) => void;
  toastOnGenericError?: boolean;
  children: ReactNode;
  submitPristine?: boolean;
  id?: string;
}

/** A convenience hook for accessing the `FormContext` directly */
export const useFormContext = () => useContext(FormContext);

/** HOF to allow fieldErrors to be read and set from within the form itself */
export function withFormContext<Values extends object>(
  Component: (props: FormikFormProps<Values>) => JSX.Element
) {
  return (componentProps: FormikFormProps<Values>) => {
    const [fieldErrors, setFieldErrors] = useState<FormContextFieldErrors>(
      FORM_CONTEXT_DEFAULT_FIELD_ERRORS
    );

    const resetFieldErrors = useCallback(
      () => setFieldErrors(FORM_CONTEXT_DEFAULT_FIELD_ERRORS),
      []
    );

    const setFieldError = useCallback((name: string, error: string) => {
      setFieldErrors((existingErrors) => ({
        ...existingErrors,
        [name]: error,
      }));
    }, []);

    return (
      <FormContext.Provider
        value={{ fieldErrors, setFieldErrors, resetFieldErrors, setFieldError }}
      >
        <Component {...componentProps} />
      </FormContext.Provider>
    );
  };
}
