import { object, string } from "yup";

/**
 * @deprecated - use `Yup` validation
 */
const validateRequired = (fieldNames) => {
  const shape = {};
  fieldNames.forEach((f) => (shape[f] = string().required("Required.")));

  return object().shape(shape);
};

export default validateRequired;
