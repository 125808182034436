import styled from "styled-components";

export const StyledCheckbox = styled.label`
  position: relative;
  display: grid;
  grid-template-columns: 14px auto;
  grid-column-gap: 0.625rem;

  input[type="checkbox"] {
    visibility: hidden;
    position: absolute;
    left: 0;

    &:checked + .icon-container {
      color: ${({ theme }) => theme.colors.textGray.darker};
    }

    &:disabled + .icon-container {
      background: #ededed;
      cursor: not-allowed;
    }
  }

  .icon-container {
    border: 1px solid rgba(198, 179, 196, 0.7);
    border-radius: 1px;
    display: inline-flex;
    height: 14px;
    width: 14px;
    color: transparent;
    cursor: pointer;
    transform: translateY(2px);
    transition: color 0.15s ease;
  }
`;
