import { useContext } from "react";
import { FormikContext } from "formik";
import Highlight from "@/atoms/account/Highlight";
import cx from "classnames";

/**
 * @deprecated - use `components/DLS/DLSErrorMessage.tsx`
 */
const FormErrorBanner = ({ messageMap = { form: null }, className }) => {
  const { errors } = useContext(FormikContext);
  const displayableErrors = Object.keys(messageMap).filter((k) => errors[k]);

  return displayableErrors.length ? (
    <Highlight className={cx("grid gap-2", className)} error>
      {displayableErrors.map((k) => (
        <div className={"font-bold"} key={k}>
          {messageMap[k] ?? errors[k]}
        </div>
      ))}
    </Highlight>
  ) : null;
};

export default FormErrorBanner;
