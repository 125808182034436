import { forwardRef, ReactNode } from "react";
import { Field, useField } from "formik";
import TextField from "@/atoms/TextField";
import { useFormContext } from "@/atoms/account/formik/FormProvider";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  name: string;
  children?: ReactNode;
  helpText?: string;
  tooltip?: string;
  tooltipMinWidth?: number;
  isLoginPasswordField?: boolean;
}

/**
 * @deprecated - use `public-ui-shared-react` `TextInput` component
 */
export const FormikTextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      helpText,
      placeholder,
      tooltip,
      tooltipMinWidth,
      id,
      label,
      isLoginPasswordField,
      ...props
    },
    ref
  ) => {
    const [field, meta] = useField(name);
    const { fieldErrors } = useFormContext();

    const error = meta.touched && meta.error;

    const attrs = {
      name,
      label,
      helpText,
      tooltip,
      tooltipMinWidth,
      id: id || name,
      placeholder: placeholder || `Enter your ${label?.toLowerCase()}`,
    };

    return (
      <Field
        ref={ref}
        {...field}
        as={TextField}
        {...props}
        {...attrs}
        error={error}
        // persist `fieldErrors` returned from backend for login password inputs,
        // since they display a "Forgot password?" link
        isLoginPasswordField={isLoginPasswordField}
        validate={() => (isLoginPasswordField ? fieldErrors?.[name] : null)}
      />
    );
  }
);

export default FormikTextField;
