import styled from "styled-components";
import { useField } from "formik";
import PropTypes from "prop-types";

const RadioInput = styled.input.attrs(() => ({
  type: "radio",
}))`
  height: 1.5rem;
  width: 1.5rem;
  border: none !important;
  position: relative;
  outline: none;
  appearance: none;
  border-radius: 1.5rem;
  flex-shrink: 0;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    background: #e0e0e0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 1.5rem;
    background: transparent;
    position: absolute;
    top: calc(0.75rem - 5px);
    left: calc(0.75rem - 5px);
    box-shadow: 0 0 0 0 transparent;
    transition: 0.15s all ease-out;
  }

  &:checked:after {
    background: #fff;
    box-shadow: 0 0 0 calc(0.75rem - 5px) #596eca;
  }
`;

const StyledRadioField = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  span {
    font-size: 15px;
    font-weight: 500;
    color: #646464;
  }

  input:checked + span {
    color: ${({ theme }) => theme.colors["ink-black"]};
    font-weight: 700;
  }
`;

const RadioField = ({ name, label, value }) => {
  const [field] = useField(name);
  const checked = field.value === value;

  return (
    <StyledRadioField>
      <RadioInput
        {...field}
        name={name}
        value={value}
        defaultChecked={checked}
      />
      <span className={"ml-4"}>{label}</span>
    </StyledRadioField>
  );
};

/**
 * @deprecated - use `components/GuidedSignUp/GuidedSignUpRadio` and its variants
 */
const RadioGroup = ({ options, id, name, className, ariaLabelledBy }) => {
  return (
    <div
      className={className}
      id={id}
      role={"group"}
      aria-labelledby={ariaLabelledBy}
    >
      {options.map(({ label, value }) => (
        <RadioField name={name} label={label} value={value} key={value} />
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  ariaLabelledBy: PropTypes.string.isRequired,
};

export default RadioGroup;
